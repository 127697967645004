import React from 'react'
import styled, { css, th } from '@xstyled/styled-components'
import { TextLink, TitleSection, H3 } from '@atoms/Typography'
import { Container, Col } from '@atoms/Grid'
import ProjectList from '@molecules/ProjectList'
import { breakpoints } from '@xstyled/system'

const Projects = ({ item, data }) => {
  const { title, linkButton, linkUrl } = item
  const { projects } = data

  return (
    <S.Wrapper id="work">
      <S.Container>
        <H3 as="h3" mb={{ xs: 30, md: 80, xl: 100 }}>
          {title}
        </H3>
      </S.Container>
      <ProjectList home="true" data={projects} mb={{ xs: 15, md: 25 }} />
      <S.WrapperLink>
        <S.Container>
          <TextLink internal invertColor="true" to={linkUrl} arrowRight>
            {linkButton}
          </TextLink>
        </S.Container>
      </S.WrapperLink>
    </S.Wrapper>
  )
}

const S = {}

S.Wrapper = styled.box`
  ${breakpoints({
    xs: css`
      margin-top: 50;
      margin-bottom: 45;
    `,
    md: css`
      margin-top: 100;
      margin-bottom: 75;
    `,
  })}
`

S.Container = styled(Container)``

S.WrapperLink = styled.box`
  text-align: left;
`

export default Projects
