import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

import { Section, Container, Row, Col } from '@atoms/Grid'
import { H2, H3, H4, Text, TextLink } from '@atoms/Typography'

import idgen from '@utils/idgen'

const ServicesSection = ({ data }) => {
  const { sectionTitle, services, paragraphs, title } = data
  return (
    <Section mb={{ xs: 75, lg: 200 }}>
      <Container>
        <Row>
          <Col col={{ xs: 1, xl: 9 / 12 }}>
            <Row>
              <H2 as="h3" mb={{ xs: 20, md: 30 }}>
                {sectionTitle}
              </H2>
              <Text mb={{ xs: 20, md: 60 }}>{paragraphs}</Text>
              <Text as="h2" mb={{ xs: 15, md: 20 }}>
                {title}
              </Text>
            </Row>
            <Row mx={{ xs: -30, md: -25 }}>
              {services.map((service) => (
                <Col
                  key={idgen()}
                  col={{ xs: 1, md: 1 / 2 }}
                  px={{ md: 10 }}
                  pr={{ xs: 0, lg: 15 }}
                >
                  <Link title={`Product ${service.title}`} to={service.linkUrl}>
                    <S.PillWrapper>
                      <S.ImageContainer>
                        <GatsbyImage
                          key={idgen()}
                          image={service.image.childImageSharp.gatsbyImageData}
                          alt={service.title}
                        />
                      </S.ImageContainer>
                      <S.TextContainer>
                        <S.H4 as="h3">{service.title}</S.H4>
                        <S.Text>{service.description}</S.Text>
                      </S.TextContainer>
                    </S.PillWrapper>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const S = {}

S.H3 = styled(H3)`
  letter-spacing: -0.02em;
  margin-bottom: 20;
  ${breakpoints({
    xs: css`
      line-height: 1.2;
      font-size: 28px;
    `,
    md: css`
      font-size: 42px;
    `,
    md: css`
      font-size: 48px;
    `,
  })}
`

S.PillWrapper = styled.div`
  background: #25232f;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

S.ImageContainer = styled.div`
  width: 110px;
  height: 110px;
`
S.TextContainer = styled.div`
  padding: 20px;
  flex: 1;
`

S.H4 = styled(H4)`
  ${breakpoints({
    xs: css`
      font-size: 20;
    `,
  })}
`

S.Text = styled(Text)`
  ${breakpoints({
    xs: css`
      font-size: 15;
    `,
  })}
`

S.TextLink = styled(TextLink)`
  justify-self: start;
  ${breakpoints({
    md: css`
      margin-top: 22;
    `,
  })}
`

export default ServicesSection
