import React, { useRef, useEffect } from 'react'
import styled, { x, css } from '@xstyled/styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import ModalPortal from '@atoms/ModalPortal'
import ModalContent from '@molecules/ModalContent'

const Modal = ({ children, showModal, dismissModal }) => {
  const modalEl = useRef(null)
  const onClickNotInModalDismiss = (event) => {
    if (!modalEl.current.contains(event.target)) {
      dismissModal()
    }
  }

  const handleUserKeyPress = (evt) => {
    const evtEl = evt || window.event
    if (evtEl.keyCode == 27) {
      dismissModal()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [showModal])

  return (
    <ModalPortal>
      {showModal && (
        <ModalContainer
          showModal={showModal}
          onClick={onClickNotInModalDismiss}
        >
          <AnimatePresence>
            {showModal && (
              <ModalOuter
                initial={{ opacity: 0, x: 0, y: '100%' }}
                animate={{
                  opacity: 1,
                  y: '0%',
                  transition: { type: 'spring', stiffness: 50, duration: 0.2 },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.5,
                  transition: { duration: 0.6 },
                }}
                layout
              >
                <ModalBox ref={modalEl}>
                  <ModalContent dismissModal={dismissModal}>
                    {children}
                  </ModalContent>
                </ModalBox>
              </ModalOuter>
            )}
          </AnimatePresence>
        </ModalContainer>
      )}
    </ModalPortal>
  )
}

export default Modal

const ModalOuter = styled(motion.div)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBox = styled(motion.div)`
  position: relative;
  z-index: 2;
  width: 80vw;
  height: calc(80vw / (16 / 9));
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fff; */
`

const ModalContainer = styled.div`
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: ${({ showModal }) => (showModal ? 'visible' : 'hidden')};
`

const ToggleBtn = styled(motion.button)`
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  padding: 0.5rem 0.8rem;
  margin-top: 3rem;
  background: #3bb75e;
  text-decoration: none;
  border: none;
  border-radius: 50px;
`
