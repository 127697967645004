import React from 'react'
import { ifProp } from 'styled-tools'
import { StaticQuery, graphql } from 'gatsby'
import { th } from '@xstyled/system'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'

import { H2 } from '@atoms/Typography'
import { variant } from '@utils/variant'
import { Section, Container, Row, Col } from '@atoms/Grid'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Text } from '@atoms/Typography'
import { ButtonLink, ButtonLinkExternal } from '@atoms/Button'
import ListItemsTick from '@atoms/ListItemsTick'

const DiscoveryQuote = (props) => {
  const { html, frontmatter = {} } = props.markdownRemark
  const {
    buttonText,
    buttonLink,
    buttonExternal,
    quoteTitle,
    quoteCite,
    list,
    image,
    alt,
  } = frontmatter
  const __html = transformMDtoHTML(html)

  return (
    <x.div as="aside" display="block" my={{ xs: 50, xl: 100 }}>
      <Container>
        <S.Wrapper>
          <Row mx="0">
            <S.Image
              image={image.childImageSharp.gatsbyImageData}
              alt={alt}
              placeholder="blurred"
            />
            <Col
              ml={{ xl: 'auto' }}
              col={{ xs: 1, md: 1, xl: 7 / 12 }}
              textAlign={{ xl: 'center' }}
              mb={{ xl: 130 }}
              px={0}
            >
              <figure>
                <H2 as="blockquote" mb={{ xs: 10, md: 15 }}>
                  {quoteTitle}
                </H2>
                <Text as="figcaption" fontSize={{ xs: 17, xl: 24 }}>
                  {quoteCite}
                </Text>
              </figure>
            </Col>
          </Row>
          <Row mx="0">
            <Col px="0" col={{ xs: 1, xl: 8 / 12 }}>
              <HTMLContent
                fontSize={{ xs: 17, md: 20 }}
                textAlign="left"
                dangerouslySetInnerHTML={{
                  __html,
                }}
              />
            </Col>
            <Col
              col={{ xs: 1, md: 4 / 12, xl: 3 / 12 }}
              ml={{ md: `${(8 / 12) * 100}%`, xl: `${(1 / 12) * 100}%` }}
              textAlign="left"
              px="0"
            >
              <x.div mb={{ xs: 100, md: 200, xl: 0 }}>
                <S.ButtonLink
                  variant="green"
                  to={buttonLink}
                  arrowRight
                  external={buttonExternal}
                  mb="20"
                >
                  {buttonText}
                </S.ButtonLink>

                <ListItemsTick list={list} />
              </x.div>
            </Col>
          </Row>
        </S.Wrapper>
      </Container>
    </x.div>
  )
}

export default function DiscoveryQuoteQuery() {
  return (
    <StaticQuery
      query={graphql`
        query DiscoveryQuoteQuery {
          markdownRemark(frontmatter: { id: { eq: "discoveryQuote" } }) {
            id
            frontmatter {
              id
              list
              quoteCite
              quoteTitle
              buttonLink
              buttonExternal
              buttonText
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 586
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
              alt
            }
            html
          }
        }
      `}
      render={DiscoveryQuote}
    />
  )
}

const S = {}

S.Image = styled(GatsbyImage)`
  position: absolute;
  bottom: -120px;

  ${breakpoints({
    md: css`
      width: 700px;
      height: 570px;
      bottom: -100px;
      left: -180px;
    `,
    xl: css`
      width: 840px;
      height: 683.75px;
      top: -100px;
      left: -180px;
    `,
  })}
`

S.Wrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: #25232f;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  padding: 40px 20px 120px;
  ${breakpoints({
    md: css`
      padding: 54px 40px 80px;
    `,
    xl: css`
      padding: 150px 80px 70px;
    `,
  })}
`

S.Content = styled.box`
  margin: 0 auto;
  ${breakpoints({
    xs: css`
      max-width: 315;
    `,
    md: css`
      max-width: 660;
    `,
  })}
`

S.ButtonLinkExternal = styled(ButtonLinkExternal)`
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      width: unset;
      padding-left: 80;
      padding-right: 80;
    `,
  })}
`

S.ButtonLink = styled(ButtonLink)`
  ${breakpoints({
    xs: css`
      min-width: 255;
    `,
    md: css`
      width: unset;
      min-width: unset;
      padding-left: 40;
      padding-right: 40;
    `,
  })}
`
