import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import Layout from '@organisms/Layout'
import Hero from '@organisms/Home/Hero'
import Section from '@organisms/Home/Section'
import ExternalSections from '@organisms/Home/ExternalSections/ExternalSections'
import LogosList from '@molecules/LogosList'
import FullServiceStudio from '@organisms/Home/FullServiceStudio'
import Quote from '@organisms/Home/Quote'
import DiscoveryQuote from '@molecules/DiscoveryQuote'
import ServicesSection from '@organisms/Home/ServicesSection'
import idgen from '@utils/idgen'

const Home = ({ data }) => {
  const { blocks, meta } = data.index.nodes[0].frontmatter

  return (
    <Layout title={meta.title} description={meta.description} invertColor>
      <S.Main>
        {blocks.map((item, index) => {
          switch (item.type) {
            case 'hero':
              return <Hero data={item} key={`${idgen()}-${index}`} />
            case 'section':
              return <Section data={item} key={`${idgen()}-${index}`} />
            case 'externalSection':
              return (
                <ExternalSections
                  data={data}
                  item={item}
                  key={`${idgen()}-${index}`}
                />
              )
            case 'logosList':
              return <LogosList data={item} key={`${idgen()}-${index}`} />
            case 'fullServiceStudio':
              return (
                <FullServiceStudio data={item} key={`${idgen()}-${index}`} />
              )
            case 'servicesSection':
              return <ServicesSection data={item} key={`${idgen()}-${index}`} />
            case 'discoveryQuote':
              return <DiscoveryQuote key={`${idgen()}-${index}`} />
            case 'quote':
              return <Quote data={item} key={`${idgen()}-${index}`} />
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

export default Home

const S = {}

S.Main = styled.mainBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};

  ${breakpoints({
    xs: css`
      padding-top: 220;
    `,
    md: css`
      padding-top: 272;
    `,
  })}
`

export const pageQuery = graphql`
  query LandingPage {
    index: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "index" } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
          blocks {
            services {
              type
              title
              description
              linkUrl
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            logoList {
              logoItem {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
    feed: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "blog" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 6
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          date_published: date(formatString: "YYYY-MM-DD")
          date_modified: date(formatString: "YYYY-MM-DD")
          excerpt
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(width: 387, layout: CONSTRAINED, aspectRatio: 1.5)
            }
          }
          ...AuthorFragment
        }
        timeToRead
      }
    }
    projects: allMarkdownRemark(
      filter: {
        frontmatter: { collection: { eq: "project" }, featured: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___order }
      limit: 4
    ) {
      nodes {
        ...ProjectsFragment
      }
    }
  }
`
