import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import { variant } from '@utils/variant'
import { TitleCard } from '@atoms/Typography'
import { Container, Row, Col } from '@atoms/Grid'
// import TextContainer from './TextContainer'

const Quote = ({ data, home, ...props }) => {
  const { copy, cite, position, company, avatar, invertBackground } = data
  return (
    <S.Wrapper
      as="blockquote"
      variant={invertBackground ? 'dark' : 'light'}
      {...props}
    >
      <Container>
        <Row alignItems="center" justifyContent="center">
          <Col col={{ xs: 1, md: 11 / 12 }}>
            <S.Content
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
            <S.AvatarContainer>
              <S.Avatar>
                <GatsbyImage
                  image={avatar.childImageSharp.gatsbyImageData}
                  style={{ borderRadius: '50px' }}
                  alt={`${cite} ${position}, ${company}`}
                />
              </S.Avatar>
              <div as="cite">
                <S.Paragraph fontWeight="medium">{cite}</S.Paragraph>
                <S.Paragraph>{`${position}, ${company}`}</S.Paragraph>
              </div>
            </S.AvatarContainer>
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  )
}

const S = {}

S.Wrapper = styled.section`
  text-align: center;
  ${variant({
    default: 'light',
    variants: {
      light: css`
        ${breakpoints({
          xs: css`
            color: ${th('colors.white')};
          `,
          xl: css`
            background: ${th('colors.grey900')};
          `,
        })}
      `,
      dark: css`
        background: ${th('colors.dark')};
        color: ${th('colors.light')};
      `,
    },
  })}

  ${breakpoints({
    xs: css`
      padding-top: 50;
      padding-bottom: 50;
    `,
    xl: css`
      padding-top: 130;
      padding-bottom: 130;
    `,
  })}
`

S.Content = styled(TitleCard)`
  ${breakpoints({
    xs: css`
      line-height: 1.2;
      margin-bottom: 25;
    `,
    md: css`
      line-height: 1.4;
      margin-bottom: 30;
    `,
  })}
`

S.AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

S.Avatar = styled.div`
  max-width: 50;
  margin-right: 15;
  border-radius: 50%;

  [data-main-image] {
    border-radius: 50%;
  }
`

S.Paragraph = styled.pBox`
  ${breakpoints({
    xs: css`
      font-size: 14;
    `,
    md: css`
      font-size: 16;
    `,
  })}
`

export default Quote
