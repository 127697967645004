import React, { useState } from 'react'
import styled, { x, css } from '@xstyled/styled-components'
import {
  motion,
  useDragControls,
  useMotionValue,
  useTransform,
} from 'framer-motion'

const ModalContent = ({ dismissModal, children }) => {
  const [dragLocked, setDragLocked] = useState(false)
  const y = useMotionValue(0)
  const dragControls = useDragControls(y)

  const input = [-200, 0, 200]
  const output = [0, 1, 0]
  const opacity = useTransform(y, input, output)

  const startDrag = (event) => {
    if (!dragLocked) {
      dragControls.start(event, { snapToCursor: true })
    }
  }

  async function handleDragEnd(event, info) {
    // console.log(info.offset.y, info.velocity.y)
    const offset = info.offset.y

    if (offset < -200 || offset > 200) {
      dismissModal()
    }
  }
  return (
    <ModalContentBox
      drag="y"
      style={{ y, opacity }}
      onDragStart={startDrag}
      dragConstraints={{ top: 0, bottom: 0 }}
      dragElastic={0.4}
      dragPropagation={false}
      dragControls={dragControls}
      onDragEnd={handleDragEnd}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.3 } }}
    >
      {children}
    </ModalContentBox>
  )
}

export default ModalContent

const ModalContentBox = styled(motion.div)`
  width: 100%;
  height: 100%;
`
