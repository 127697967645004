import React, { useRef, useState, useEffect } from 'react'
import { get } from 'lodash'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { Text } from '@atoms/Typography'
import { Container, containerStyle, Col, Row } from '@atoms/Grid'
import FadeInView from '@atoms/FadeInView'

import Modal from '@molecules/Modal'
import LazyVideo from '@molecules/LazyVideo'
import Plyr from '@atoms/Plyr'

import PlayLight from '@assets/svg/play--light.svg'
import ArrowDown from '@assets/svg/arrow-down.svg'

const Hero = ({ data }) => {
  const {
    title,
    paragraphs,
    videoSourceYouTubeID,
    videoPreview,
    videoThumbnail,
  } = data
  const videoRef = useRef(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (showModal) {
      return videoRef?.current && videoRef.current.pause()
    }
    return videoRef?.current && videoRef.current.play()
    return () => {}
  }, [showModal])

  const getTop = (el) =>
    !el ? 0 : el.offsetTop + (el.offsetParent && getTop(el.offsetParent))

  return (
    <section>
      <S.Container>
        <Row>
          <Col col={{ xs: 1, md: 11 / 12, xl: 9 / 12 }}>
            <FadeInView>
              <S.Title as="h1" dangerouslySetInnerHTML={{ __html: title }} />
              <S.Description
                as="h2"
                dangerouslySetInnerHTML={{ __html: paragraphs }}
              />
              <x.div
                display="block"
                my="40px"
                cursor="pointer"
                onClick={() => {
                  const el = document.querySelector('#video')
                  return window?.scrollTo({
                    top: getTop(el) - 35,
                    behavior: 'smooth',
                  })
                }}
              >
                <ArrowDown width="50px" height="50px" />
              </x.div>
            </FadeInView>
          </Col>
        </Row>
      </S.Container>
      <FadeInView>
        <S.VideoContainer position="relative" id="video">
          {videoSourceYouTubeID && (
            <S.VideoWrap>
              <LazyVideo
                videoRef={videoRef}
                videoPreview={videoPreview}
                videoThumbnail={videoThumbnail}
              />

              <S.PlayOverlay onClick={() => setShowModal(true)}>
                <S.PlayButton>
                  <S.PlayLight />
                </S.PlayButton>
              </S.PlayOverlay>

              <Modal
                showModal={showModal}
                dismissModal={() => setShowModal(false)}
              >
                <Plyr
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: videoSourceYouTubeID,
                        provider: 'youtube',
                      },
                    ],
                  }}
                  options={{
                    autoplay: true,
                    controls: [
                      'play-large',
                      'play',
                      'progress',
                      'current-time',
                      'mute',
                      'volume',
                      'fullscreen',
                    ],
                  }}
                />
              </Modal>
            </S.VideoWrap>
          )}
        </S.VideoContainer>
      </FadeInView>
    </section>
  )
}

export default Hero

const S = {}

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      margin-bottom: 0;
    `,
  })}
`

S.PlayOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

S.PlayButton = styled.button`
  border: 0;
  outline: none;
  width: 50px;
  height: 50px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
  ${breakpoints({
    xl: css`
      width: 100px;
      height: 100px;
    `,
  })}
`

S.PlayLight = styled(PlayLight)`
  width: 50px;
  height: 50px;
  ${breakpoints({
    xl: css`
      width: 100px;
      height: 100px;
    `,
  })}
`

S.Title = styled(Text)`
  ${breakpoints({
    xs: css`
      font-weight: ${th('fontWeights.normal')};
      font-size: 15;
      margin-bottom: 23;
      line-height: 22px;
      letter-spacing: -0.02em;
    `,
    md: css`
      margin-bottom: 20;
    `,
    xl: css`
      line-height: 30px;
      font-size: 24;
      margin-bottom: 33;
    `,
  })}
  & .break {
    ${breakpoints({
      xs: css`
        display: block;
      `,
      md: css`
        display: none;
      `,
    })}
  }
`

S.Description = styled.box`
  font-weight: ${th('fontWeights.medium')};
  letter-spacing: -0.03em;
  ${breakpoints({
    xs: css`
      font-size: 30;
      line-height: 120%;
    `,
    md: css`
      font-weight: ${th('fontWeights.medium')};
      font-size: 42;
      margin-bottom: 0;
      letter-spacing: -0.02em;
    `,
    xl: css`
      font-size: 64px;
    `,
  })}
`

S.VideoContainer = styled.div`
  ${breakpoints({
    xs: css`
      margin-bottom: 50;
    `,
    md: css`
      ${containerStyle}
    `,
    xl: css`
      ${containerStyle}/* margin-bottom: 100; */
    `,
  })}
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  cursor: pointer;
  transition: transform 0.25s ease;
  will-change: transform, opacity;
  :hover {
    transform: translate3d(0, -5%, 0) scale(1.01185);
  }
`

S.Video = styled.video`
  ${breakpoints({
    md: css``,
  })}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

S.VideoWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

S.VideoRelative = styled.div`
  position: relative;
`
