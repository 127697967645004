import React from 'react'
import styled, { x, css } from '@xstyled/styled-components'

import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

export default function PlyrWrapper({ source, options }) {
  return (
    <PlyrStyled>
      <Plyr source={source} options={options} />
    </PlyrStyled>
  )
}

const PlyrStyled = styled.div`
  & > div {
    width: 100%;
    height: 100%;
  }
`
