import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css, x } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { Section, Container, Row, Col } from '@atoms/Grid'

import { ButtonLink } from '@atoms/Button'
import TitleTextParagraph from '@molecules/TitleTextParagraph'
import FadeInView from '@atoms/FadeInView'

import ImageSlider from './ImageSlider'

const FullServiceStudio = ({ data }) => {
  const { title, paragraphs, linkButton, linkUrl, items } = data
  return (
    <x.div id="about" as="section" my={{ xs: 50, lg: 100, xl: 200 }}>
      <Container>
        <Row alignItems="center">
          <Col col={{ xs: 1, xl: 6 / 12 }} mb={{ xs: 80, md: 20, xl: 0 }}>
            <FadeInView>
              <StaticQuery
                query={graphql`
                  query CareersCount {
                    allMarkdownRemark(
                      filter: {
                        frontmatter: {
                          collection: { eq: "careers" }
                          status: { eq: "published" }
                        }
                      }
                    ) {
                      totalCount
                    }
                  }
                `}
                render={({ allMarkdownRemark }) => (
                  <S.ButtonLink
                    mb={{ xs: 30 }}
                    variant="grey900"
                    to="/careers"
                    arrowRight
                    mb={{ xs: 20 }}
                  >
                    <x.div as="span" fontWeight="400">
                      We’re hiring! <b>{allMarkdownRemark.totalCount} jobs</b>{' '}
                      live now
                    </x.div>
                  </S.ButtonLink>
                )}
              />
            </FadeInView>

            <TitleTextParagraph
              title={title}
              paragraphs={paragraphs}
              linkButton={linkButton}
              linkUrl={linkUrl}
            />
          </Col>
          <Col col={{ xs: 1, xl: 5 / 12 }} ml={{ xl: `${(1 / 12) * 100}%` }}>
            <ImageSlider items={items} />
          </Col>
        </Row>
      </Container>
    </x.div>
  )
}

const S = {}

S.ButtonLink = styled(ButtonLink)`
  ${breakpoints({
    xs: css`
      min-width: 255;
    `,
    md: css`
      height: 46px;
    `,
  })}
`

export default FullServiceStudio
