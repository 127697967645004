import React from 'react'
import Projects from './Projects'
import Feed from './Feed'

const ExternalSections = ({ item, data }) => {
  const { section } = item
  return (
    <>
      {section === 'projects' && <Projects item={item} data={data} />}
      {section === 'feed' && <Feed item={item} data={data} />}
    </>
  )
}

export default ExternalSections
