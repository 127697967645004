import React, { useRef } from 'react'
import { get } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import useIntersectionObserver from '@react-hook/intersection-observer'

const LazyVideo = ({ videoThumbnail, videoPreview, videoRef }) => {
  const containerRef = useRef()
  const lockRef = useRef(false)

  const image = getImage(videoThumbnail)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true
  }
  return (
    <S.VideoRelative ref={containerRef}>
      <S.Img>
        <GatsbyImage image={image} alt={'Crowdform Showreel UI/UX 2021'} />
      </S.Img>

      <x.div position="relative">
        <S.Video
          id="player"
          loop
          muted
          autoPlay
          preload="auto"
          playsInline
          ref={videoRef}
        >
          {lockRef.current && (
            <source
              src={videoPreview.publicURL}
              type={get(videoPreview, 'video.internal.mediaType', 'video/mp4')}
            />
          )}
        </S.Video>
      </x.div>
    </S.VideoRelative>
  )
}

export default LazyVideo

const S = {}

S.Img = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

S.Video = styled.video`
  ${breakpoints({
    md: css``,
  })}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

S.VideoRelative = styled.div`
  position: relative;
`
