import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'

import { TitleSection, TextLink, H2, H3 } from '@atoms/Typography'
import FadeInView from '@atoms/FadeInView'
import HTMLContent from '@atoms/HTMLContent'
import transformMDtoHTML from '@utils/transformMDtoHTML'

const Paragraphs = ({ paragraphs }) => (
  <>
    {!isEmpty(paragraphs) && (
      <S.HTMLContent
        dangerouslySetInnerHTML={{
          __html: transformMDtoHTML(paragraphs),
        }}
      />
    )}
  </>
)

const TitleTextParagraph = ({
  title,
  paragraphs,
  sectionTitle,
  linkUrl,
  linkButton,
}) => (
  <>
    <FadeInView>
      {sectionTitle && (
        <H3 as="h3" mb={{ xs: 40, md: 50 }}>
          {sectionTitle}
        </H3>
      )}
      <H2 as="h4" mb="20">
        {title}
      </H2>
    </FadeInView>
    <FadeInView>
      <Paragraphs paragraphs={paragraphs} />
    </FadeInView>
    <S.WrapperLink>
      <S.TextLink invertColor="true" internal to={linkUrl} arrowRight>
        {linkButton}
      </S.TextLink>
    </S.WrapperLink>
  </>
)

export default TitleTextParagraph

const S = {}

S.HTMLContent = styled(HTMLContent)`
  ${breakpoints({
    xs: css`
      font-size: 17;
      margin-bottom: 50;
      line-height: 140%;
    `,
    md: css`
      font-size: 22;
      margin-bottom: 38;
    `,
    xl: css`
      font-size: 24;
      margin-bottom: 38;
      line-height: 150%;
    `,
  })}
`

S.TextLink = styled(TextLink)`
  ${breakpoints({
    xs: css`
      font-size: 18;
      font-weight: ${th.fontWeight('normal')}
      line-height: 1.4;
      text-align: center;
    `,
    md: css`
      font-size: 19;
      text-align: left;
    `,
  })}
`

S.WrapperLink = styled.box`
  ${breakpoints({
    xs: css`
      margin-bottom: 40px;
    `,
    md: css`
      margin-bottom: 75px;
    `,
  })}
`
