import React from 'react'
import { isEmpty } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Text } from '@atoms/Typography'
import { transformMDtoText } from '@utils/transformMDtoHTML'
import { Col, Container, Row } from '@atoms/Grid'
import idgen from '@utils/idgen'

const LogosList = ({ data }) => {
  const items = isEmpty(data.logoList) ? [] : data.logoList
  const __html = transformMDtoText(data.sectionTitle)
  return (
    <S.Container>
      <S.Title dangerouslySetInnerHTML={{ __html }} />
      <Row alignItems="center">
        {items.map((item) => (
          <S.Col col={{ xs: 1 / 3, md: 1 / 6 }} px={{ xs: 0 }} key={idgen()}>
            <S.Logo image={item.logoItem.childImageSharp.gatsbyImageData} />
          </S.Col>
        ))}
      </Row>
    </S.Container>
  )
}

const S = {}

S.Title = styled(Text)`
  ${breakpoints({
    xs: css`
      font-weight: ${th('fontWeights.normal')};
      font-size: 17;
      margin-bottom: 12;
      line-height: 23.8px;
      letter-spacing: -0.02em;
      text-align: center;
    `,
    md: css`
      margin-bottom: 15;
      font-size: 22;
      line-height: 28.6px;
    `,
    xl: css`
      font-size: 24;
      margin-bottom: 30;
    `,
  })}
  & br {
    ${breakpoints({
      xl: css`
        display: none;
      `,
    })}
`

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      padding-top: 40;
      padding-bottom: 40;
    `,
    md: css`
      padding-top: 60;
      padding-bottom: 60;
    `,
    xl: css`
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 200;
      padding-bottom: 100;
    `,
  })}
`

S.Col = styled(Col)`
  ${breakpoints({
    xs: css`
      margin-bottom: 0;
    `,
    md: css`
      margin-bottom: 20;
    `,
  })}
`

S.LogoBox = styled.div`
  background-color: transparent;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

S.LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

S.Logo = styled(GatsbyImage)`
  display: block;
  height: auto;
  width: 100%;
  filter: saturate(0);
  ${breakpoints({
    xs: css`
      margin: 0 auto;
      max-width: 108px;
    `,
    md: css`
      max-width: 91px;
    `,
    xl: css`
      max-width: 168px;
    `,
  })}
`

export default LogosList
