import React from 'react'
import styled, { x, css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Container, Row, Col } from '@atoms/Grid'

import TitleTextParagraph from '@molecules/TitleTextParagraph'

const Section = ({ data }) => {
  const { sectionTitle } = data
  const { image, title, paragraphs, linkButton, linkUrl } = data.items[0]

  return (
    <S.SectionContainer>
      <Row>
        <Col col={{ xs: 1, xl: 5 / 12 }} order={{ xs: 2, xl: 1 }}>
          {image && (
            <S.ImageWrapper>
              <GatsbyImage
                image={getImage(image)}
                aspectRatio={0.5}
                layout="fullWidth"
                alt={title}
              />
            </S.ImageWrapper>
          )}
        </Col>
        <Col
          col={{ xs: 1, xl: 6 / 12 }}
          ml={{ xl: `${(1 / 12) * 100}%` }}
          order={{ xs: 1, xl: 1 }}
        >
          <TitleTextParagraph
            sectionTitle={sectionTitle}
            title={title}
            paragraphs={paragraphs}
            linkButton={linkButton}
            linkUrl={linkUrl}
          />
        </Col>
      </Row>
    </S.SectionContainer>
  )
}

const S = {}

S.SectionContainer = styled(Container)`
  overflow: hidden;
  ${breakpoints({
    xs: css`
      padding-top: 50;
      margin-top: -50;
      margin-bottom: 52;
    `,
    md: css`
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 160.5;
    `,
  })}
`

S.ImageWrapper = styled.divBox`
  & .gatsby-image-wrapper {
    > div:first-child {
      ${breakpoints({
        xs: css`
          padding-top: 100% !important;
        `,
        md: css`
          padding-top: 67.44186047% !important;
        `,
        xl: css`
          padding-top: 141.98782961% !important;
        `,
      })}
    }
  }
`

export default Section
