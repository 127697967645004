import React, { useState } from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { x, css } from '@xstyled/styled-components'

import { th, breakpoints } from '@xstyled/system'
import { ifProp } from 'styled-tools'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Controller } from 'swiper'

import { ButtonLink } from '@atoms/Button'
import theme from '@src/theme'

// // Import Swiper styles
import 'swiper/scss'

const ImageSlider = ({ items }) => {
  // store swiper instances
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const [sliderIndex, setSliderIndex] = useState(0)

  return (
    <S.Container>
      <x.div
        maxWidth={{ md: '445px', xl: 'auto' }}
        css={css`
          width: 100%;
        `}
      >
        <Swiper
          modules={[Controller]}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          onSlideChange={(e) => setSliderIndex(e.realIndex)}
          breakpoints={{
            0: {
              // loop: true,
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            [theme.screens.md]: {
              slidesPerView: 1,
            },
          }}
        >
          {items.map((item, i) => {
            return (
              <SwiperSlide key={`${item.title}-${i}`}>
                <x.div position="relative">
                  <x.div
                    display={{ md: 'none' }}
                    css={css`
                      text-align: center;
                      margin-bottom: 15;
                      font-size: 24px;
                    `}
                  >
                    <span>{item.title}</span>
                  </x.div>
                  <S.Image
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt={item.alt}
                  />
                  {item.linkUrl && (
                    <x.div position="absolute" bottom="20" right="20">
                      <ButtonLink to={item.linkUrl} variant="white" arrowRight>
                        {item.linkButton}
                      </ButtonLink>
                    </x.div>
                  )}
                </x.div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </x.div>
      <x.div
        flex={{ md: 1 }}
        ml={{ md: 40, xl: 0 }}
        display={{ xs: 'none', md: 'flex' }}
        css={css`
          .swiper-container {
            width: 100%;
          }
        `}
      >
        <Swiper
          modules={[Controller]}
          onSwiper={setSecondSwiper}
          breakpoints={{
            0: {
              spaceBetween: 0,
            },
            [theme.screens.md]: {
              slidesPerView: 'auto',
              direction: 'vertical',
            },
            [theme.screens.xl]: {
              slidesPerView: 'auto',
              direction: 'horizontal',
            },
          }}
        >
          {items.map(({ title }, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  width: 'auto',
                  display: 'inline-block',
                  height: '50px',
                }}
              >
                {({ isActive }) => {
                  return (
                    <S.Button
                      key={title}
                      active={sliderIndex === index}
                      onClick={() => firstSwiper.slideToLoop(index)}
                    >
                      <span>{title}</span>
                    </S.Button>
                  )
                }}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </x.div>
    </S.Container>
  )
}

const test = css`
  width: auto;
`

export default ImageSlider

const S = {}

S.Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${breakpoints({
    md: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `,
    xl: css`
      display: block;
    `,
  })}
`

S.Slider = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  height: 100%;

  z-index: 1;
`

S.ButtonSlider = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  height: 100%;
  z-index: 1;
`

S.Image = styled(GatsbyImage)`
  pointer-events: none;
`

S.Button = styled.button`
  font-size: 24px;
  cursor: pointer;
  margin-top: 20;
  margin-right: 20;
  margin-bottom: 15px;
  &:hover {
    span {
      /* transition: all 0.3s ease; */
      padding-bottom: 4px;
      border-bottom: 1px solid ${th('colors.white')};
    }
  }

  ${ifProp(
    'active',
    '',
    css`
      opacity: 0.5;
    `
  )}
`
