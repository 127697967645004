import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints, th } from '@xstyled/system'
import { useStaticQuery, graphql } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Autoplay } from 'swiper'

import theme from '@src/theme'
import QuoteComponent from '@organisms/ProjectSingle/Quote'

import 'swiper/scss'
import 'swiper/scss/effect-fade'
import 'swiper/scss/autoplay'

const Quote = () => {
  const data = useStaticQuery(graphql`
    query QuoteQuery {
      quotes: allMarkdownRemark(
        filter: {
          frontmatter: {
            collection: { eq: "quotes" }
            status: { eq: "published" }
          }
        } # sort: { order: DESC, fields: frontmatter___sort }
      ) {
        nodes {
          html
          frontmatter {
            title
            subtitle
            company
            avatar {
              childImageSharp {
                gatsbyImageData(width: 50, height: 50, layout: FIXED)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <S.Section>
      <Swiper
        breakpoints={{
          0: {
            // loop: true,
            slidesPerView: 1,
            spaceBetween: 25,
          },
          [theme.screens.md]: {
            slidesPerView: 1,
          },
        }}
        autoplay={{
          delay: 5000,
        }}
        speed={800}
        modules={[EffectFade, Autoplay]}
        effect="fade"
      >
        {data.quotes.nodes.map((item, i) => {
          return (
            <SwiperSlide key={`${i}`}>
              <S.Quote
                data={{
                  copy: item.html,
                  cite: item.frontmatter.title,
                  position: item.frontmatter.subtitle,
                  company: item.frontmatter.company,
                  avatar: item.frontmatter.avatar,
                  invertBackground: false,
                }}
                home
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </S.Section>
  )
}

const S = {}

S.Section = styled.section`
  background: ${th('colors.grey900')};
  ${breakpoints({
    xs: css`
      padding-top: 50;
      padding-bottom: 50;
    `,
    xl: css`
      padding-top: 90;
      padding-bottom: 90;
    `,
  })}
`

S.Quote = styled(QuoteComponent)`
  padding-top: 0;
  padding-bottom: 0;
  background: ${th('colors.grey900')};
`

export default Quote
