import React from 'react'
import { isEmpty, get } from 'lodash'
import styled, { css } from '@xstyled/styled-components'
import { Link } from 'gatsby'
import { breakpoints, th } from '@xstyled/system'

import { TitleSection, TextLink, H3 } from '@atoms/Typography'
import { Section, Container, Row, Col } from '@atoms/Grid'
import FadeInView from '@atoms/FadeInView'
import Posts from '@organisms/Feed/Posts'

const Feed = ({ item, data }) => {
  const { feed } = data
  const { linkButton, linkUrl, title } = item
  const posts = get(feed, 'nodes', [])

  if (isEmpty(posts)) return null

  return (
    <Section py={{ xs: 50 }}>
      <Container>
        <Row>
          <H3 as="h4" mb={{ xs: 40, md: 60, xs: 100 }}>
            {title}
          </H3>
        </Row>
        <Row>
          <Posts data={{ nodes: posts }} />
        </Row>
        <Row>
          <S.FeedLinkContainer>
            <S.FeedLink internal invertColor="true" to={linkUrl}>
              {linkButton}
            </S.FeedLink>
          </S.FeedLinkContainer>
        </Row>
      </Container>
    </Section>
  )
}

const S = {}

S.TextLink = styled(TextLink)`
  line-height: 1.4;
  ${breakpoints({
    xs: css`
      font-size: 15;
    `,
    md: css`
      font-size: 19;
    `,
  })}
`

S.FeedLink = styled(TextLink)`
  ${breakpoints({
    xs: css`
      font-size: 18;
      line-height: 1.4;
      text-align: center;
    `,
    md: css`
      font-size: 22;
    `,
  })}
`

S.FeedLinkContainer = styled.div`
  ${breakpoints({
    xs: css`
      display: flex;
      justify-content: center;
    `,
    md: css`
      display: inline;
    `,
  })}
`

export default Feed
